const Skills=()=>{
    return(
        <div className="skills" data-aos='slide-up' data-aos-duration='3000'>
            <h3>What's I am good at</h3>
          <div className="row">
            <div className="col-12 col-lg-6 skill">
                <p>HTML</p>
                <div className="w3-border">
                    <div className=" html-label pb" ></div>
                </div>
            </div>
            <div className="col-12 col-lg-6 skill">
                <p>CSS</p>
                <div className="w3-border">
                    <div className=" css-label pb" ></div>
                </div>
            </div>
            <div className="col-12 col-lg-6 skill">
                <p>JavaScript</p>
                <div className="w3-border">
                    <div className=" js-label pb" ></div>
                </div>
            </div>
            <div className="col-12 col-lg-6 skill">
                <p>React JS</p>
                <div className="w3-border">
                    <div className=" react-label pb" ></div>
                </div>
            </div>
            <div className="col-12 col-lg-6 skill">
                <p>Nodejs</p>
                <div className="w3-border">
                    <div className=" nodejs-label pb" ></div>
                </div>
            </div>
          </div>
        </div>
    )
}
export default Skills;