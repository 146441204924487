const AboutMe=()=>{
    return(
        <div className="CTN mt-2 ">
            <div className="aboutMe " data-aos='slide-right' data-aos-duration='3000'>
                <h3>About Me</h3>
                <p>
                    At the heart of every project is a commitment to excellence.
                    I am a self-taught Front-End Developer with a passion for learning new technologies.
                    But I am currently a 2nd Year Computer Science Student at UCSS.
                    I am always seeking to improve my skills and knowledge.
                    I am currently looking for full-time or part-time job opportunities.
                    I embark on each endeavor with a relentless pursuit of perfection. My journey is a testament to a blend of academic knowledge,
                     hands-on experience, and an insatiable curiosity that fuels my creative spirit.
                </p>
            </div>
            <div className='PSP ' data-aos='slide-right' data-aos-duration='3000'>
                <h4>Passion and Purpose</h4>
                <p>
                Beyond the pixels and lines of code, my work is fueled by a passion for creativity.
                 Whether it's Web Development or Software Engineering,I believe in the power of self-enpowerment
                  and strive to make a meaningful impact in the dynamic world of Web Development.
                </p>
            </div>
            <div className='cnt  ' data-aos='slide-left' data-aos-duration='3000'>
                <h4>Let's connect</h4>
                <p>
                Thank you for visiting my digital sanctuary. I invite you to explore,
                 be inspired, and consider the possibilities of collaboration. If you're seeking a dedicated professional who brings a fresh perspective and a proven track record of success,
                 let's connect! I'm excited about the potential for our paths to intersect.
                 </p>
            </div>
            <div className='services' data-aos='slide-up' data-aos-duration='3000'>
                <h3>What can I do for you?</h3>
                <dl>
                    <dt>Simplest Web Development</dt>
                    <dd>The frontend Web development with clean and simplest code</dd>
                    <dt>Responsive Web Development</dt>
                    <dd>Responsive Web development with clean and simple code</dd>
                    <dt>Advanced Web Development</dt>
                    <dd>Advanced Web development with clean and advanced code</dd>
                </dl>
            </div>
        </div>
    )
}
export default AboutMe;