import IntroCard from "../components/introCard";
import AboutMe from "../components/aboutMe";
import Skills from "../components/skills";
const Home=()=>{
    return(
        <div className="Home container-fluid p-3 ">
            <IntroCard/>
            <AboutMe/>
            <Skills/>
        </div>
    )
}
export default Home;