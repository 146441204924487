import {useEffect} from 'react'
import { useProjectContext } from '../hooks/usePojectContext';
import ProjectDetails from '../components/projectDetails';
const ProjectsPage=()=>{
    const {projects,dispatch}=useProjectContext();
    useEffect(()=>{
        const fetchProjects=async()=>{
            const response=await fetch('https://portfolioapi-b9x2.onrender.com/api/project/get');
            const json=await response.json();
            if(response.ok){
                dispatch({type:'SET_PROJECTS',payload:json})
            }
        }
        fetchProjects();
    })
    return(
        <div className="container-fluid mt-2">
            <h1 className='PJPageHeader'>Projects I have been worked for . . . </h1>
            <div className="projects ">
                {projects && projects.map((project)=>(
                <ProjectDetails key={project._id} project={project}/>
                ))}
            </div>
        </div>
    )
}
export default ProjectsPage;