import { useState } from "react"
const ContactPage=()=>{
    const [name,setName]=useState("")
    const [email,setEmail]=useState("")
    const [message,setMessage]=useState("")
    const[error,setError]=useState("")
    const [noti,setNoti]=useState("")
    const messageHandler=async (e)=>{
        e.preventDefault();
        const feedback={name,email,message}
        const response=await fetch('https://portfolioapi-b9x2.onrender.com/api/feedback/add',{
            method: 'POST',
            body: JSON.stringify(feedback),
            headers:{
                'Content-Type':'application/json'
            }
        })
        const json=await response.json();
        if(!response.ok){
            setError(json.error)
        }
        else{
            setName('')
            setEmail('')
            setMessage('')
            setError(null)
            setNoti(json.message)
        }
    }
    return(
        <div className="contact">
            <h1 className="contactPageHeader">Contact Page</h1>
            <div className="contactPage">
            <div className="contactInfo">
                <table className=" table-borderless">
                    <tr>
                        <td className='text-white text-end'>Phone <i className="fas fa-phone-alt"></i></td>
                        <td><a target="_blank" rel="noreferrer" className='text-white' href="tel:+959662486582">+959662486582</a></td>
                    </tr>
                    <tr>
                        <td className='text-white text-end'>Email <i className="fas fa-envelope-open"></i></td>
                        <td><a target="_blank"  rel="noreferrer" className='text-white' href="mailto:pyaephyohlaing2kk3@gmail.com">pyaephyohlaing2kk3<br/>@gmail.com</a></td>
                    </tr>
                    <tr>
                        <td className='text-white text-end'>Facebook <i className="fab fa-facebook"></i></td>
                        <td><a target="_blank"  rel="noreferrer" className='text-white' href="https://www.facebook.com/pyaephyo.hlaing.007">Pyare Phyoe Hlaing</a></td>
                    </tr>
                    <tr>
                        <td className='text-white text-end'>Github <i className="fab fa-github"></i></td>
                        <td><a target="_blank" rel="noreferrer" className='text-white' href="https://github.com/pyaephyo11979">pyaephyo11979</a></td>
                    </tr>
                    <tr>
                        <td className='text-white text-end'>LinkedIn <i className="fab fa-linkedin"></i></td>
                        <td><a target="_blank" rel="noreferrer"  className='text-white'href="https://www.linkedin.com/in/pyae-phyo-hlaing-66b0961aa/">Pyae Phyo Hlaing</a></td>
                    </tr>
                    <tr>
                        <td className='text-white text-end '>Instagram <i className="fab fa-instagram"></i></td>
                        <td><a target="_blank" rel="noreferrer" className='text-white' href="https://www.instagram.com/pyae_p_h/">pyae_p_h</a></td>
                    </tr>
                </table>
            </div>

            <div className="contactForm">
                <h2>Get In Touch <i className="fas fa-envelope"></i></h2>
                <form onSubmit={messageHandler}>
                    <div className="form-floating mt-2">
                        <input type="text" name='name' onChange={(e)=>setName(e.target.value)} value={name} className="form-control" id="floatingInput" placeholder="Name"/>
                        <label for="floatingInput">Name</label>
                    </div>
                    <div className="form-floating mt-2">
                        <input type="email" name='email' onChange={(e)=>setEmail(e.target.value)} value={email} className="form-control" id="floatingInput" placeholder="Email"/>
                        <label for="floatingInput">Email</label>
                    </div>
                    <div className="form-floating mt-2">
                        <input className="form-control" name='message' onChange={(e)=>setMessage(e.target.value)} value={message} placeholder="Leave a comment here" id="floatingTextarea"></input>
                        <label for="floatingTextarea">Message</label>
                    </div>
                    <div className="mt-2">
                        <button type="submit" className="btn btn-primary">Sent Now <i className="fas fa-paper-plane"></i></button>
                    </div>
                </form>
                {error && <div className="alert mt-2 alert-danger">{error} <button className="btn btn-close " data-bs-dismiss='alert'></button></div>}
                {noti && <div className='alert mt-2 alert-success'>{noti} <button className="btn btn-close" data-bs-dismiss='alert'></button></div>}
            </div>
            </div>
        </div>
    )
}
export default ContactPage