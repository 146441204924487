import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Home from './pages/Home'
import Navbar from './components/Navbar';
import ProjectsPage from './pages/Projects';
import ContactPage from './pages/Contact';
function App() {
  return (
    <BrowserRouter>
    <Navbar/>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/projects' element={<ProjectsPage/>}/>
      <Route path='/contact' element={<ContactPage/>}/>
    </Routes>
    </BrowserRouter>
  );
}

export default App;
