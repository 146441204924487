const IntroCard=()=>{
    let color='green';
    return(
        <div className="IntroCard row mt-2" data-aos='flip-right' data-aos-duration='3000'>
                <div className="introTxT mt-auto col-12 col-lg-8 " >
                    <p className="txt">
                         Hello and welcome! I'm Pyae Phyo Hlaing, a passionate fornt-end developer on a mission to transform ideas into 
                         engaging experiences. This portfolio is a curated collection of my journey 
                         in WebDevelopment, showcasing the intersection of creativity, innovation, 
                         and meticulous craftsmanship. <a className="btn fs-4" target="_blank" style={{color:color}} rel="noreferrer" href='https://drive.google.com/file/d/1LGtm5TlJp6mwN5U2knWx4mz5jNF-1nkw/view?usp=sharing'>Download My Resume <i className="fa fa-download"></i></a>
                    </p>
                </div>
                <div className="introImg col-12 col-lg-4">
                    <img src="./images/IMG_7365.PNG" alt="profile" className=""/>
                </div>
        </div>
    )
}
export default IntroCard;