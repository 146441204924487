
const ProjectDetails=({project})=>{
    return(
        <div className="projectDetail " data-aos='slide-up' data-aos-duration='3000'>
            <div className="pjHeader">
                <h3>{project.title}</h3>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6 ">
                    <img src={project.image} alt={project.title} className="img-fluid rounded-2"/>
                </div>
                <div className="d-none d-lg-block col-12 col-lg-6">
                    <h4>{project.title}</h4>
                    <p>{project.description}</p>
                </div>
            </div>
            <hr/>
            <div className="mt-2">
                <a href={project.ghlink} target="_blank" rel="noreferrer" className="btn btn-info me-1">View SourceCode <i className="fas fa-code"></i></a>
                <a href={project.link} target="_blank" rel="noreferrer" className="btn btn-primary">View Demo <i className="fas fa-arrow-circle-right"></i></a>
            </div>
        </div>
    )
}
export default ProjectDetails;