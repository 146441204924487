import {Link}from 'react-router-dom'
const Navbar=()=>{
    return(
        <nav className="Navbar bg-dark navbar-dark sticky-top navbar  sticky-top navbar-expand ">
            <a href="/" className="navbar-brand ms-2"><h2>Portfolio</h2></a>
            <div className="container d-lg-flex justify-content-end ">
                <div className="navbar-nav">
                <div className="nav-item"><Link className='nav-link' to='/'>Home <i className='fas fa-home'></i></Link></div>
                    <div className="nav-item"><Link className='nav-link' to='/projects'>Projects <i className='fas fa-folder-open'></i></Link></div>
                    <div className="nav-item"><Link className='nav-link' to='/contact'>Contact Me <i className='fas fa-id-card-clip'></i></Link></div>
                </div>
            </div>
        </nav>
    )
}
export default Navbar;